/*
 * @Description:
 * @Author: transparent
 * @Date: 2021-11-15 15:05:07
 * @LastEditTime: 2022-03-07 16:32:55
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/comment.js
 */
import request from '@/utils/request';

//评论与回复列表
export function getComments(param) {
    return request({
        url: '/admin/comment/list',
        method: 'post',
        data: param
    })
}

//新增评论与回复
export function addComments(param) {
    return request({
        url: '/admin/comment/add',
        method: 'post',
        data: param
    })
}

//查询提醒列表
export function getCommentsNotice(param) {
    return request({
        url: '/admin/comment/notice',
        method: 'post',
        data: param
    })
}



//设置已读/不再提醒
export function setCommentNoticed(param) {
  return request({
    url: '/admin/comment/set-noticed',
    method: 'post',
    data: param
  })
}



//查询提醒列表(新)
export function getUserNoticeList(param) {
    return request({
        url: '/admin/user-notice/paging-list',
        method: 'post',
        data: param
    })
}

// 设置通知为已阅
export function updateNoticed(param) {
    return request({
        url: '/admin/user-notice/update-to-noticed',
        method: 'post',
        data: param
    })
} 