<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-11-15 14:12:50
 * @LastEditTime: 2022-03-30 17:10:19
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/comment/comment.vue
-->
<!--评论模块-->
<template>
  <div class="container">
    <div class="show" v-if="commentsShow">
      <div class="top">
        <span class="number">评论 共{{ comments.length }}条</span>
        <el-button size="medium" @click="newComment">发表新评论</el-button>
      </div>
       <div class="noComment" v-if="newCommentsShow">
      <transition name="fade">
        <div class="input-wrapper">
          <el-input
            class="gray-bg-input"
            v-model="inputComment"
            type="textarea"
            :rows="3"
            autofocus
            placeholder="写下你的评论"
          >
          </el-input>
          <div class="btn-control">
            <span class="cancel" @click="cancelNewComments">取消</span>
            <el-button
              class="btn"
              type="success"
              round
              @click="commitComment(subjectId)"
              >发布评论</el-button
            >
          </div>
        </div>
      </transition>
    </div>
      <div class="comment" v-for="(item, index) in comments" :key="index">
        <div class="info">
          <img
            class="avatar"
            :src="
              item.fromAvatar
                ? item.fromAvatar
                : 'https://sy-hermes.oss-cn-beijing.aliyuncs.com/static/default/defaultAvatar.jpg'
            "
            width="36"
            height="36"
          />
          <div class="right">
            <div class="name">{{ item.fromUserName }}</div>
            <div class="date">{{ item.formatCommentTime }}</div>
          </div>
        </div>
        <div class="content">{{ item.content }}</div>
        <div class="control">
          <span class="comment-reply" @click="showCommentInput(item)">
            <i class="iconfont icon-comment"></i>
            <span>回复</span>
          </span>
        </div>
        <div class="reply">
          <div class="item" v-for="(reply, index) in item.reply" :key="index">
            <div class="reply-content">
              <span class="from-name">{{ reply.fromUserName }}</span>
              <span>：</span>
              <span @click="quoteComment(item, reply)" class="commentContent">{{
                reply.content
              }}</span>
            </div>
            <div class="quote flexJfAc" v-show="reply.quoteCommentContent">
              <span class="">{{ reply.quoteCommentUserName }}：</span>
              <span>{{ reply.quoteCommentContent }}</span>
            </div>
            <div class="reply-bottom">
              <span>{{ reply.formatCommentTime }}</span>
              <!-- <span class="reply-text" @click="showCommentInput(item, reply)">
                <i class="iconfont icon-comment"></i>
                <span>回复</span>
              </span> -->
            </div>
          </div>
          <div
            class="write-reply"
            v-if="item.reply.length > 0"
            @click="showCommentInput(item)"
          >
            <i class="el-icon-edit"></i>
            <span class="add-comment">添加新评论</span>
          </div>
          <transition name="fade">
            <div class="input-wrapper" v-if="showItemId === item.id">
              <el-input
                class="gray-bg-input"
                v-model="inputComment"
                type="textarea"
                :rows="3"
                autofocus
                placeholder="写下你的评论"
              >
              </el-input>
              <div class="quote flexJfAc" v-show="quoteInfo.quoteId">
                <span class="">{{ quoteInfo.toName }}：</span>
                <span>{{ quoteInfo.quoteContent }}</span>
              </div>
              <div class="btn-control">
                <span class="cancel" @click="cancel">取消</span>
                <el-button
                  class="btn"
                  type="success"
                  round
                  @click="commitComment"
                  >确定</el-button
                >
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
import Vue from "vue";
import { getComments, addComments } from "@/api/comment";

export default {
  props: {
    subjectType: {
      type: String,
      required: true,
    },
    subjectId: {
      type: String,
      required: true,
    },
    commentLoading: Boolean,
  },
  components: {},
  data() {
    return {
      comments: null,
      inputComment: "",
      showItemId: "",
      quoteInfo: {
        quoteId: null,
        quoteContent: null,
        toName: null,
      },
      commentsShow: false,
      newCommentsShow: false,
      subjectIdNow: null,
      addCommentType: 1, // 1:评论，2:回复某一评论
    };
  },
  watch:{
     subjectId: {
      handler(newVal, old) {
        if (newVal) {
          this.getComments();
          this.subjectIdNow = this.subjectId;
        }
      },
      immediate: true,
      deep: true,
    },
     commentLoading: {
      handler(newVal, old) {
        if (newVal) {
          this.getComments();
          this.subjectIdNow = this.subjectId;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  mounted() {
   
  },
  methods: {
    newComment() {
      this.addCommentType = 1;
      this.showItemId = null;
      this.subjectIdNow = this.subjectId;
      this.newCommentsShow = true;

    },
    getComments() {
      var that = this;
      let param = {
        subjectType: this.subjectType,
        subjectId: this.subjectId,
      };
      getComments(param).then((res) => {
        if (res.success) {
          let comments = res.data.data;
          this.comments = comments;
          this.commentsShow = true;
          if (comments.length == 0) {
            this.newCommentsShow = true;
          }
        }
      });
    },
    /**
     * 引用评论
     */
    quoteComment(item, reply) {
      //   console.log(item);
      //   console.log(reply);
      console.log(reply.id);
      this.addCommentType = 2;
      this.newCommentsShow = false;
      this.inputComment = "";
      this.showItemId = item.id;
      this.quoteInfo.quoteId = reply.id;
      this.quoteInfo.quoteContent = reply.content;
      this.quoteInfo.toName = reply.fromUserName;
    },
    /**
     * 点赞
     */
    likeClick(item) {
      if (item.isLike === null) {
        Vue.$set(item, "isLike", true);
        item.likeNum++;
      } else {
        if (item.isLike) {
          item.likeNum--;
        } else {
          item.likeNum++;
        }
        item.isLike = !item.isLike;
      }
    },

    /**
     * 点击取消按钮
     */
    cancel() {
      this.showItemId = ""; 
    },
     cancelNewComments() { 
      this.newCommentsShow = false;
    },

    /**
     * 提交评论
     */
    commitComment(addCommentType) {
      if(!this.inputComment){
        this.$message({ type: "warning", message: "请填写评论内容!" });
        return false;
      }
      let info;
      if (this.addCommentType == 1) {
        info = {
          subjectType: this.subjectType,
          subjectId: this.subjectIdNow,
          content: this.inputComment,
        };
      } else if (this.addCommentType == 2) {
        info = {
          replyCommentId: this.showItemId,
          quoteCommentId: this.quoteInfo.quoteId,
          content: this.inputComment,
        };
      }

      addComments(info).then((res) => {
        if (res.success) {
          this.$message({ type: "success", message: "评论成功!" });
          this.getComments();
          this.newCommentsShow = false;
          this.inputComment = null;
          if (this.addCommentType ) {
            this.quoteInfo.quoteId = null;
            this.quoteInfo.quoteContent = null;
            this.quoteInfo.toName = null;
          }
        }
      });
    },

    /**
     * 点击评论按钮显示输入框
     * item: 当前大评论
     * reply: 当前回复的评论
     */
    showCommentInput(item, reply) {
      console.log(item);
      console.log(reply);
      this.newCommentsShow = false;
      //   this.quoteInfo = {}
      this.addCommentType = 2;
      this.subjectIdNow = item.id;
      if (reply) {
        this.inputComment = "@" + reply.fromName + " ";
      } else {
        this.inputComment = "";
        this.quoteInfo.quoteId = null;
        this.quoteInfo.quoteContent = null;
        this.quoteInfo.toName = null;
      }
      this.showItemId = item.id;
    },
  },
  created() {
    console.log("评论");
  },
};
</script>

<style scoped lang="scss">
@import "@/components/comment/commentStyle.scss";

.container {
  padding: 0 10px;
  box-sizing: border-box;
  .top {
      margin-bottom: 10px;
    .number {
      margin-right: 20px;
    }
  }
  .comment {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid $border-fourth;
    .info {
      display: flex;
      align-items: center;
      .avatar {
        border-radius: 50%;
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .name {
          font-size: 16px;
          color: $text-main;
          margin-bottom: 5px;
          font-weight: 500;
        }
        .date {
          font-size: 12px;
          color: $text-minor;
        }
      }
    }
    .content {
      font-size: 16px;
      color: $text-main;
      line-height: 20px;
      padding: 10px 0;
    }
    .control {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $text-minor;
      .like {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        &.active,
        &:hover {
          color: $color-main;
        }
        .iconfont {
          font-size: 14px;
          margin-right: 5px;
        }
      }
      .comment-reply {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          color: $text-333;
        }
        .iconfont {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
    .reply {
      margin: 10px 0;
      border-left: 2px solid $border-first;
      .item {
        margin: 0 10px;
        padding: 10px 0;
        border-bottom: 1px dashed $border-third;
        .reply-content {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: $text-main;
          .from-name {
            color: $color-main;
          }
          .to-name {
            color: $color-main;
            margin-left: 5px;
            margin-right: 5px;
          }
          .commentContent {
            cursor: pointer;
          }
        }
        .quote {
          background-color: #e1e1e1;
          padding: 1px 5px;
          width: max-content;
          border-radius: 2px;
          span {
            display: block;
            color: #5e5e5e;
          }
        }
        .reply-bottom {
          display: flex;
          align-items: center;
          margin-top: 6px;
          font-size: 12px;
          color: $text-minor;
          .reply-text {
            display: flex;
            align-items: center;
            margin-left: 10px;
            cursor: pointer;
            &:hover {
              color: $text-333;
            }
            .icon-comment {
              margin-right: 5px;
            }
          }
        }
      }
      .write-reply {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $text-minor;
        padding: 10px;
        cursor: pointer;
        &:hover {
          color: $text-main;
        }
        .el-icon-edit {
          margin-right: 5px;
        }
      }
      .fade-enter-active,
      fade-leave-active {
        transition: opacity 0.5s;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .input-wrapper {
        padding: 10px;
        .gray-bg-input,
        .el-input__inner {
          /*background-color: #67C23A;*/
        }
        .quote {
          background-color: #e1e1e1;
          padding: 1px 5px;
          width: max-content;
          border-radius: 2px;
          margin-top: 10px;
          span {
            display: block;
            color: #676767;
          }
        }
      }
    }
  }
}
.btn-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  .cancel {
    font-size: 16px;
    color: $text-normal;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      color: $text-333;
    }
  }
  .confirm {
    font-size: 16px;
  }
}
</style>

